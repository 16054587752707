import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo/Seo"
import Numbered from "../components/Headings/Numbered"
import Heading from "../components/Headings/Heading"
import SubHeading from "../components/Headings/SubHeading"
import Sidebar from "../components/Sidebar/Sidebar"
import {
  FaArrowLeft,
  FaGamepad,
  FaIdBadge,
  FaLaptop,
  FaToolbox,
  FaTools,
} from "react-icons/fa"
import styled from "styled-components"
import Accordion from "../components/Sidebar/Accordion"
import parseCategoryData from "../utils/parseCategoryData"
import Title from "../components/Headings/Title"

const Wrap = styled.div`
  max-height: 77vh;
  display: flex;
`

const InnerScroll = styled.div`
  overflow: hidden;
  overflow-y: scroll;
  width: 100%;
`

const DEPage = ({ data }) => {
  const subjectCategories = parseCategoryData(data.allMdx.edges)
  const [ToggleSidebar, setToggleSidebar] = useState(true)
  const toggle = () => setToggleSidebar(!ToggleSidebar)

  return (
    <Layout>
      <Seo title="Digital Electronics" />
      <section className="de-section">
        <Title>Digital Electronics</Title>
        <div className="container-fluid py-5">
          <div className="row justify-content-center">
            {ToggleSidebar && (
              <div
                className="col-lg-3 mb-4"
                id="sticky-sidebar"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div
                  className="card shadow border-primary sticky-top"
                  style={{
                    backgroundColor:
                      "#" + Math.random().toString(16).slice(-6) + "10",
                  }}
                >
                  <div className="card-header text-center">
                    <h5 className="fat-text">Chapters</h5>
                  </div>
                  <Wrap>
                    <InnerScroll className="card-body small no-scrollbar">
                      <Accordion allowMultipleOpen>
                        {Object.keys(subjectCategories).map((category, i) => {
                          return (
                            <div key={i} label={category} isOpen>
                              <Sidebar
                                subjectLink={`digital_electronics`}
                                category={subjectCategories[category]}
                                target="ToggleSidebar"
                              />
                            </div>
                          )
                        })}
                      </Accordion>
                    </InnerScroll>
                  </Wrap>
                </div>
              </div>
            )}

            <div className={ToggleSidebar ? "col-lg-9 mb-4" : "col-lg-12 mb-4"}>
              <div
                className="card border-primary shadow"
                style={{
                  backgroundColor:
                    "#" + Math.random().toString(16).slice(-6) + "10",
                }}
              >
                <div className="card-header">
                  <button
                    onClick={toggle}
                    id="ToggleSidebar"
                    className="btn btn-outline-primary btn-sm"
                  >
                    <FaArrowLeft /> Toggle Sidebar
                  </button>
                </div>

                <div className="card-body">
                  <Heading>What is Digital Electronics ?</Heading>

                  <p>
                    {" "}
                    Digital electronics is the branch of electronics that deals
                    with digital signals, which are discrete signals that take
                    on a finite set of values. These values are usually
                    represented by binary digits, or bits, which can either be 0
                    or 1. Digital electronics is essential in modern technology,
                    and it is used in a wide range of devices, including
                    computers, smartphones, and digital televisions.
                    <br />
                    <br />
                    One of the main advantages of digital electronics is its
                    ability to process and transmit data accurately and quickly.
                    Digital signals are less susceptible to interference and
                    noise, which can distort analog signals. This means that
                    digital systems can provide more reliable and consistent
                    results than analog systems.
                    <br />
                    <br />
                    Digital electronics also allows for the manipulation and
                    processing of data through logic gates, which are electronic
                    circuits that perform logical operations on digital signals.
                    Logic gates are the building blocks of digital circuits, and
                    they are used to create complex systems that can perform a
                    wide range of functions.
                    <br />
                    <br />
                    One of the most widely used digital circuits is the
                    microprocessor, which is the central processing unit (CPU)
                    of a computer. The microprocessor is a complex digital
                    circuit that can execute millions of instructions per
                    second, and it is responsible for controlling all of the
                    functions of the computer.
                    <br />
                    <br />
                    Digital electronics has also revolutionized the
                    telecommunications industry. Digital signals can be
                    compressed, encrypted, and transmitted over long distances
                    using fiber-optic cables. This has enabled the development
                    of high-speed internet and mobile phone networks, which have
                    transformed the way we communicate and access information.
                    <br />
                    <br />
                    In addition to its practical applications, digital
                    electronics has also led to many innovations in science and
                    technology. Digital signal processing is used in a wide
                    range of fields, including audio and image processing,
                    medical imaging, and radar systems.
                    <br />
                    <br />
                    Despite its many benefits, digital electronics also has some
                    drawbacks. Digital circuits require a constant power supply
                    to operate, and they can be vulnerable to electromagnetic
                    interference (EMI). Additionally, digital circuits can be
                    more complex and difficult to design than analog circuits.
                    <br />
                    <br />
                    In conclusion, digital electronics is a crucial field that
                    has transformed modern technology. It has enabled the
                    development of high-speed computing, telecommunications, and
                    signal processing systems that have revolutionized the way
                    we live and work. As technology continues to evolve, digital
                    electronics will play an increasingly important role in
                    shaping the future of the world.
                    <br />
                  </p>

                  <Heading>
                    What are the advantages of learning Digital Electronics ?
                  </Heading>
                  <p>
                    There are several advantages of learning digital
                    electronics, including:
                    <br />
                    <br />
                    <SubHeading>
                      Understanding modern technology
                    </SubHeading>{" "}
                    Digital electronics is the foundation of modern technology,
                    and by learning digital electronics, you can gain a deeper
                    understanding of how devices like computers, smartphones,
                    and televisions work.
                    <br />
                    <br />
                    <SubHeading>Career opportunities</SubHeading> There is a
                    high demand for professionals who have expertise in digital
                    electronics. By learning digital electronics, you can open
                    up career opportunities in a wide range of industries,
                    including telecommunications, aerospace, medical devices,
                    and defense.
                    <br />
                    <br />
                    <SubHeading>Design and innovation</SubHeading> Digital
                    electronics enables the creation of complex systems that can
                    perform a wide range of functions. By learning digital
                    electronics, you can gain the skills to design and build
                    innovative products and systems that can have a significant
                    impact on society.
                    <br />
                    <br />
                    <SubHeading>Reliable and accurate</SubHeading> Digital
                    electronics provides a more reliable and accurate way of
                    processing and transmitting data. This is because digital
                    signals are less susceptible to interference and noise, and
                    can provide consistent results.
                    <br />
                    <br />
                    <SubHeading>Versatility</SubHeading> Digital electronics can
                    be used in a wide range of applications, from controlling
                    traffic lights to designing sophisticated medical equipment.
                    By learning digital electronics, you can develop skills that
                    are transferable across many different fields.
                    <br />
                    <br />
                    <SubHeading>Automation</SubHeading> Digital electronics is
                    the foundation of automation, which has revolutionized many
                    industries. By learning digital electronics, you can gain
                    the skills to design and build automated systems that can
                    increase efficiency and productivity. Overall, learning
                    digital electronics can provide you with a valuable set of
                    skills that are in high demand across many different
                    industries. It can also enable you to understand and
                    contribute to the development of modern technology, and open
                    up opportunities for innovation and career growth.
                    <br />
                  </p>

                  <Heading>Why you should learn Digital Electronics ?</Heading>
                  <div className="row">
                    <div className="col-lg-4 col-md-6 text-center mb-4">
                      <div
                        className="card p-4 border-primary shadow"
                        style={{
                          backgroundColor:
                            "#" + Math.random().toString(16).slice(-6) + "10",
                        }}
                      >
                        <div
                          className="text-primary mb-2"
                          style={{ fontSize: "60px" }}
                        >
                          <FaIdBadge />
                        </div>
                        <h4>Career Opportunities</h4>
                        <p>
                          Digital electronics is an ever-growing field with a
                          vast range of job opportunities. The demand for
                          digital electronics experts is increasing every day,
                          and having a strong knowledge base in this field can
                          open up a wide range of career opportunities for you.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 text-center mb-4">
                      <div
                        className="card p-4 border-primary shadow"
                        style={{
                          backgroundColor:
                            "#" + Math.random().toString(16).slice(-6) + "10",
                        }}
                      >
                        <div
                          className="text-primary mb-2"
                          style={{ fontSize: "60px" }}
                        >
                          <FaLaptop />
                        </div>
                        <h4>Understanding of Electronics</h4>
                        <p>
                          Digital electronics is the foundation of all modern
                          electronic devices, including smartphones, computers,
                          and televisions. By learning digital electronics, you
                          can gain a better understanding of how these devices
                          work and how they are designed.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 text-center mb-4">
                      <div
                        className="card p-4 border-primary shadow"
                        style={{
                          backgroundColor:
                            "#" + Math.random().toString(16).slice(-6) + "10",
                        }}
                      >
                        <div
                          className="text-primary mb-2"
                          style={{ fontSize: "60px" }}
                        >
                          <FaGamepad />
                        </div>
                        <h4>Designing Circuits</h4>
                        <p>
                          Digital electronics provides you with the skills and
                          knowledge necessary to design and build complex
                          circuits and systems. This can be particularly useful
                          if you are interested in building your own electronic
                          devices.
                        </p>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6 text-center mb-4">
                      <div
                        className="card p-4 border-primary shadow"
                        style={{
                          backgroundColor:
                            "#" + Math.random().toString(16).slice(-6) + "10",
                        }}
                      >
                        <div
                          className="text-primary mb-2"
                          style={{ fontSize: "60px" }}
                        >
                          <FaToolbox />
                        </div>
                        <h4>Troubleshooting</h4>
                        <p>
                          By understanding digital electronics, you can
                          troubleshoot and repair electronic devices when they
                          fail. This can save you time and money by avoiding the
                          need to hire a technician.
                        </p>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6 text-center mb-4">
                      <div
                        className="card p-4 border-primary shadow"
                        style={{
                          backgroundColor:
                            "#" + Math.random().toString(16).slice(-6) + "10",
                        }}
                      >
                        <div
                          className="text-primary mb-2"
                          style={{ fontSize: "60px" }}
                        >
                          <FaTools />
                        </div>
                        <h4>Innovation</h4>
                        <p>
                          Digital electronics is an essential aspect of
                          technological innovation. By learning digital
                          electronics, you can contribute to the development of
                          new and exciting technologies.
                        </p>
                      </div>
                    </div>
                  </div>

                  <Heading>Who should learn Digital Electronics ?</Heading>
                  <p>
                    <SubHeading>
                      Students studying electrical engineering, computer
                      science, or related fields
                    </SubHeading>{" "}
                    Digital electronics is a foundational topic in these fields,
                    and students who are pursuing degrees in these areas will
                    need to have a solid understanding of digital electronics.
                    <br />
                    <br />
                    <SubHeading>Hobbyists and makers</SubHeading> Digital
                    electronics is a popular area of interest for hobbyists and
                    makers who enjoy building and tinkering with electronic
                    devices.
                    <br />
                    <br />
                    <SubHeading>
                      Professionals in the electronics industry
                    </SubHeading>{" "}
                    Engineers, technicians, and designers who work in the
                    electronics industry will need to have a deep understanding
                    of digital electronics in order to design and build
                    sophisticated electronic systems.
                    <br />
                    <br />
                    <SubHeading>
                      Individuals working in fields that use digital electronics
                    </SubHeading>{" "}
                    Many industries, including telecommunications, aerospace,
                    medical devices, and defense, rely on digital electronics.
                    Professionals working in these fields will need to have a
                    good understanding of digital electronics in order to be
                    effective in their roles.
                    <br />
                    <br />
                    <SubHeading>
                      Anyone interested in technology
                    </SubHeading>{" "}
                    Digital electronics is a fascinating topic that can be
                    interesting and rewarding for anyone who has an interest in
                    technology and how things work.
                    <br />
                    <br />
                    Overall, digital electronics is a versatile and important
                    field that can be useful for a wide range of individuals.
                    Whether you are pursuing a career in engineering or simply
                    have an interest in technology, learning digital electronics
                    can be a valuable and rewarding experience.
                    <br />
                  </p>
                  <Heading>Learning Objectives</Heading>

                  <div className="col-lg-12">
                    <div
                      className="card shadow rounded list-group-item border-primary mb-2"
                      style={{
                        backgroundColor:
                          "#" + Math.random().toString(16).slice(-6) + "10",
                      }}
                    >
                      <div className="d-flex w-100 justify-content-between align-items-center m-2">
                        <Numbered>1</Numbered>
                        <h5 className="m-2">
                          Understand binary number systems and number
                          conversions: Students should be able to convert
                          between binary, decimal, and hexadecimal number
                          systems, and perform basic arithmetic operations in
                          each system.
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div
                      className="card shadow rounded list-group-item border-primary mb-2"
                      style={{
                        backgroundColor:
                          "#" + Math.random().toString(16).slice(-6) + "10",
                      }}
                    >
                      <div className="d-flex w-100 justify-content-between align-items-center m-2">
                        <Numbered>2</Numbered>
                        <h5 className="m-2">
                          Understand Boolean algebra and logic gates: Students
                          should be able to use Boolean algebra to simplify
                          logic expressions and design combinational logic
                          circuits using basic logic gates such as AND, OR, and
                          NOT.
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div
                      className="card shadow rounded list-group-item border-primary mb-2"
                      style={{
                        backgroundColor:
                          "#" + Math.random().toString(16).slice(-6) + "10",
                      }}
                    >
                      <div className="d-flex w-100 justify-content-between align-items-center m-2">
                        <Numbered>3</Numbered>
                        <h5 className="m-2">
                          {" "}
                          Understand flip-flops and sequential logic circuits:
                          Students should be able to design sequential logic
                          circuits using flip-flops and understand how these
                          circuits can be used for tasks such as counting and
                          memory storage.
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div
                      className="card shadow rounded list-group-item border-primary mb-2"
                      style={{
                        backgroundColor:
                          "#" + Math.random().toString(16).slice(-6) + "10",
                      }}
                    >
                      <div className="d-flex w-100 justify-content-between align-items-center m-2">
                        <Numbered>4</Numbered>
                        <h5 className="m-2">
                          {" "}
                          Understand timing and clocking: Students should be
                          able to design synchronous digital circuits that use a
                          clock signal to synchronize the operations of multiple
                          circuits.
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div
                      className="card shadow rounded list-group-item border-primary mb-2"
                      style={{
                        backgroundColor:
                          "#" + Math.random().toString(16).slice(-6) + "10",
                      }}
                    >
                      <div className="d-flex w-100 justify-content-between align-items-center m-2">
                        <Numbered>5</Numbered>
                        <h5 className="m-2">
                          {" "}
                          Understand memory devices: Students should be able to
                          design and analyze memory circuits, including static
                          and dynamic RAM, ROM, and EPROM.
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div
                      className="card shadow rounded list-group-item border-primary mb-2"
                      style={{
                        backgroundColor:
                          "#" + Math.random().toString(16).slice(-6) + "10",
                      }}
                    >
                      <div className="d-flex w-100 justify-content-between align-items-center m-2">
                        <Numbered>6</Numbered>
                        <h5 className="m-2">
                          {" "}
                          Understand digital signal processing: Students should
                          be able to understand the basics of digital signal
                          processing, including sampling, quantization, and
                          digital filtering.
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div
                      className="card shadow rounded list-group-item border-primary mb-2"
                      style={{
                        backgroundColor:
                          "#" + Math.random().toString(16).slice(-6) + "10",
                      }}
                    >
                      <div className="d-flex w-100 justify-content-between align-items-center m-2">
                        <Numbered>7</Numbered>
                        <h5 className="m-2">
                          {" "}
                          Design and analyze digital circuits: Students should
                          be able to design and analyze digital circuits using
                          software tools such as simulation software and
                          computer-aided design tools.
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default DEPage

export const pageQuery = graphql`
  query DEPage {
    allMdx(
      filter: {
        fileAbsolutePath: { regex: "/(digital_electronics)/.*.(mdx)/" }
      }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
            topLevelDir
            subDir
          }
        }
      }
    }
  }
`
